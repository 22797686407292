<template>
    <div class="wrap">
        <channel-loading v-if="isLoading" :spacename="spaceInfo.spaceNm" :channelStatus="'changecuration'" />
        <AlertModal v-if="isAlertModal" :alertText="alertText" @closeModal="isAlertModal = false" />
        <GenreSeasonAlertModal
            v-if="isGenreSeasonAlertModal"
            @closeModal="isGenreSeasonAlertModal = false"
            @closeModal2="setNotShowSeasonModalCarol"
        />
        <div class="space_pc mb_100px">
            <section class="main myspace w_940">
                <div class="main_box">
                    <div class="main_box_title">
                        <h1 class="mb_0px">큐레이션 설정</h1>
                        <div @click="clickPre()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <ul class="curation_tab_list">
                        <li class="curation_tab_item" @click="selectTab = 1" :class="{ on: selectTab === 1 }">기본설정</li>
                        <li class="curation_tab_item" @click="selectTab = 2" :class="{ on: selectTab === 2 }">세부설정</li>
                    </ul>
                    <div class="cs_main" id="tab1" v-if="selectTab === 1">
                        <div class="myspace_content qration">
                            <div class="three_type">
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p class="mb_16px cursor_default">국내곡 비율</p>
                                    </div>
                                    <div class="type_span">
                                        <span class="countDown" @click="countDown()" :class="{ disabled: disabledDown === true }">-</span>
                                        <label class="curation_item_label_wrap percent">국내 {{ curationInfo.domesticRate }}%</label>
                                        <span class="countUp" @click="countUp()" :class="{ disabled: disabledUp === true }">+</span>
                                    </div>
                                </div>
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p class="mb_16px cursor_default">주요 고객층</p>
                                    </div>
                                    <div class="type_span">
                                        <label
                                            class="curation_item_label_wrap"
                                            v-for="item in pcGetTarget"
                                            :key="item.id"
                                            :for="item.id"
                                            :class="{ on: curationInfo.target.includes(item.value) }"
                                        >
                                            {{ item.koText }}
                                            <input hidden type="checkbox" :id="item.id" :value="item.value" v-model="curationInfo.target" />
                                        </label>
                                    </div>
                                </div>
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p class="mb_16px cursor_default">주요 인테리어 색상</p>
                                    </div>
                                    <div class="type_span">
                                        <ul class="updatecuration_color_list">
                                            <li class="updatecuration_color_list_item" v-for="item in pcGetColors1" :key="`color${item.no}`">
                                                <label
                                                    class="updatecuration_label"
                                                    :class="{ on: curationInfo.color === item.no }"
                                                    :for="`color${item.no}`"
                                                    @click="clickColor(item.no)"
                                                >
                                                    {{ item.colorNm }}
                                                </label>
                                                <input
                                                    type="radio"
                                                    :value="item.no"
                                                    hidden
                                                    :id="`color${item.no}`"
                                                    v-model="curationInfo.color"
                                                />
                                            </li>
                                        </ul>
                                        <ul class="updatecuration_color_list">
                                            <li class="updatecuration_color_list_item" v-for="item in pcGetColors2" :key="`color${item.no}`">
                                                <label
                                                    class="updatecuration_label"
                                                    :class="{ on: curationInfo.color === item.no }"
                                                    :for="`color${item.no}`"
                                                    @click="clickColor(item.no)"
                                                >
                                                    {{ item.colorNm }}
                                                </label>
                                                <input
                                                    type="radio"
                                                    :value="item.no"
                                                    hidden
                                                    :id="`color${item.no}`"
                                                    v-model="curationInfo.color"
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p class="mb_16px cursor_default">주요 인테리어 소재(최대 3개)</p>
                                    </div>
                                    <div class="type_span">
                                        <ul class="updatecuration_material_list">
                                            <li
                                                class="updatecuration_material_list_item"
                                                v-for="item in pcGetMaterial1"
                                                :key="`material${item.no}`"
                                            >
                                                <label
                                                    class="updatecuration_label"
                                                    @click="clickMaterial(item.no)"
                                                    :class="{ on: curationInfo.material.includes(item.no) }"
                                                    :for="`material${item.no}`"
                                                >
                                                    {{ item.materialNm }}
                                                </label>
                                                <input type="checkbox" :value="item.no" hidden :id="`material${item.no}`" />
                                            </li>
                                        </ul>
                                        <ul class="updatecuration_material_list">
                                            <li
                                                class="updatecuration_material_list_item"
                                                v-for="item in pcGetMaterial2"
                                                :key="`material${item.no}`"
                                            >
                                                <label
                                                    class="updatecuration_label"
                                                    @click="clickMaterial(item.no)"
                                                    :class="{ on: curationInfo.material.includes(item.no) }"
                                                    :for="`material${item.no}`"
                                                >
                                                    {{ item.materialNm }}
                                                </label>
                                                <input type="checkbox" :value="item.no" hidden :id="`material${item.no}`" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cs_main" id="tab1" v-if="selectTab === 2">
                        <div class="myspace_content qration">
                            <update-curation-dim v-if="moreDetailYn !== 'Y'" @close-dim="onCloseDim" />
                            <div class="three_type">
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p class="mb_16px cursor_default">고객 단위</p>
                                    </div>
                                    <div class="type_span">
                                        <label
                                            v-for="item in pcGetTargetunit"
                                            class="curation_item_label_wrap"
                                            :key="item.id"
                                            :for="item.id"
                                            :class="{ on: curationInfo.unit.includes(item.value) }"
                                        >
                                            {{ item.koText }}
                                            <input type="checkbox" hidden :id="item.id" :value="item.value" v-model="curationInfo.unit" />
                                        </label>
                                    </div>
                                </div>
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p class="mb_16px cursor_default">선호 장르</p>
                                    </div>
                                    <div class="type_span">
                                        <label
                                            v-for="item in pcGenre"
                                            class="curation_item_label_wrap"
                                            :class="{
                                                on: curationInfo.genre.includes(item.value),
                                                btndisabled: curationInfo.domesticRate === '0' && item.value === 10
                                            }"
                                            :key="item.id"
                                            :for="item.id"
                                            @click="clickGenreVal(item.value)"
                                        >
                                            {{ item.koText }}
                                        </label>
                                    </div>
                                </div>
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p class="mb_16px cursor_default">선호 시대</p>
                                    </div>
                                    <div class="type_span">
                                        <label
                                            v-for="item in pcPeroid"
                                            class="curation_item_label_wrap"
                                            :class="{ on: curationInfo.period.includes(item.value) }"
                                            :key="item.id"
                                            :for="item.id"
                                        >
                                            {{ item.text }}
                                            <input hidden type="checkbox" :id="item.id" :value="item.value" v-model="curationInfo.period" />
                                        </label>
                                    </div>
                                </div>

                                <div class="type_title" v-if="!isApp">
                                    <div class="radio_box">
                                        <p class="mb_16px cursor_default">
                                            선호 음악 분위기<br />
                                            (3개 이상 선택)
                                        </p>
                                    </div>
                                    <div class="type_span" :class="{ all_select: curationInfo.genre[0] === 13 }">
                                        <label
                                            v-for="item in pcEmotion"
                                            class="curation_item_label_wrap"
                                            :key="item.id"
                                            :for="item.id"
                                            :class="{ on: curationInfo.emotion.includes(item.value) }"
                                        >
                                            {{ item.koText }}
                                            <input type="checkbox" :value="item.value" :id="item.id" hidden v-model="curationInfo.emotion" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content qration">
                            <div class="three_type"></div>
                            <div class="form_bottom quation_btn">
                                <button
                                    style="text-align: center"
                                    v-if="
                                        curationInfo.target.length > 0 &&
                                            curationInfo.unit.length > 0 &&
                                            curationInfo.genre.length > 0 &&
                                            curationInfo.period.length > 0 &&
                                            curationInfo.color !== null &&
                                            curationInfo.material.length > 0 &&
                                            curationInfo.material.length < 4 &&
                                            (isApp || (!isApp && curationInfo.emotion.length > 2)) &&
                                            !btnDisalbed
                                    "
                                    @click="checkCondition()"
                                >
                                    다음
                                </button>
                                <button v-else style="text-align: center; color: #5b5b5b; background: #4d1b24" disabled>다음</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <update-curation-dim :id="'mobiledim'" v-if="selectTab === 2 && moreDetailYn !== 'Y'" @close-dim="onCloseDim" />
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                    </a>
                    <h2>큐레이션 설정</h2>
                    <div class="btn_close" @click="clickPre()">
                        <img src="/media/images/icon_close_24.svg" />
                    </div>
                </div>
            </div>
            <section class="main myspace">
                <div class="main_box">
                    <div class="cs_main">
                        <div class="myspace_content qration">
                            <ul class="curation_tab_list">
                                <li class="curation_tab_item" @click="selectTab = 1" :class="{ on: selectTab === 1 }">기본설정</li>
                                <li class="curation_tab_item" @click="selectTab = 2" :class="{ on: selectTab === 2 }">세부설정</li>
                            </ul>
                            <div class="types" v-if="selectTab === 1">
                                <div>
                                    <div class="radio_box">
                                        <p class="mo_label_title">국내곡 비율</p>
                                    </div>
                                    <div>
                                        <span class="countDown" @click="countDown()" :class="{ disabled: disabledDown === true }">-</span>
                                        <label class="curation_item_label_wrap percent">국내 {{ curationInfo.domesticRate }}%</label>
                                        <span class="countUp" @click="countUp()" :class="{ disabled: disabledUp === true }">+</span>
                                    </div>
                                </div>
                                <div>
                                    <div class="radio_box">
                                        <p class="mo_label_title">주요 고객층</p>
                                    </div>
                                    <div>
                                        <label
                                            class="curation_item_label_wrap"
                                            v-for="item in pcGetTarget"
                                            :key="item.id"
                                            :for="item.id"
                                            :class="{ on: curationInfo.target.includes(item.value) }"
                                        >
                                            {{ item.koText }}
                                            <input hidden type="checkbox" :id="item.id" :value="item.value" v-model="curationInfo.target" />
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div class="radio_box">
                                        <p class="mo_label_title">주요 인테리어 색상</p>
                                    </div>
                                    <div>
                                        <p
                                            v-for="item in moGetColors1"
                                            :key="`color${item.no}`"
                                            :class="{ on: curationInfo.color === item.no }"
                                            @click="clickColor(item.no)"
                                        >
                                            {{ item.colorNm }}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div class="radio_box">
                                        <p class="mo_label_title">주요 인테리어 소재(최대 3개)</p>
                                    </div>
                                    <div>
                                        <p
                                            v-for="item in moGetMaterial"
                                            :key="`material${item.no}`"
                                            :class="{ on: curationInfo.material.includes(item.no) }"
                                            @click="clickMaterial(item.no)"
                                        >
                                            {{ item.materialNm }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="types" v-if="selectTab === 2">
                                <div>
                                    <div class="radio_box">
                                        <p class="mo_label_title">고객단위</p>
                                    </div>
                                    <div>
                                        <label
                                            v-for="item in pcGetTargetunit"
                                            class="curation_item_label_wrap"
                                            :key="item.id"
                                            :for="item.id"
                                            :class="{ on: curationInfo.unit.includes(item.value) }"
                                        >
                                            {{ item.koText }}
                                            <input type="checkbox" hidden :id="item.id" :value="item.value" v-model="curationInfo.unit" />
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div class="radio_box">
                                        <p class="mo_label_title">선호장르</p>
                                    </div>
                                    <div>
                                        <label
                                            v-for="item in pcGenre"
                                            class="curation_item_label_wrap"
                                            :class="{
                                                on: curationInfo.genre.includes(item.value),
                                                btndisabled: curationInfo.domesticRate === '0' && item.value === 10
                                            }"
                                            :key="item.id"
                                            :for="item.id"
                                            @click="clickGenreVal(item.value)"
                                        >
                                            {{ item.koText }}
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div class="radio_box">
                                        <p class="mo_label_title">선호시대</p>
                                    </div>
                                    <div :class="{ all_select: curationInfo.genre[0] === 13 }">
                                        <label
                                            v-for="item in pcPeroid"
                                            class="curation_item_label_wrap"
                                            :class="{ on: curationInfo.period.includes(item.value) }"
                                            :key="item.id"
                                            :for="item.id"
                                        >
                                            {{ item.text }}
                                            <input hidden type="checkbox" :id="item.id" :value="item.value" v-model="curationInfo.period" />
                                        </label>
                                    </div>
                                </div>
                                <div v-if="!isApp">
                                    <div class="radio_box">
                                        <p
                                            style="border: none; margin: 0px; padding: 0px; background: rgba(255, 255, 255, 0); cursor: default"
                                        >
                                            선호 음악 분위기 (3개 이상 선택)
                                        </p>
                                    </div>
                                    <div>
                                        <label
                                            v-for="item in pcEmotion"
                                            class="curation_item_label_wrap"
                                            :key="item.id"
                                            :for="item.id"
                                            :class="{ on: curationInfo.emotion.includes(item.value) }"
                                        >
                                            {{ item.koText }}
                                            <input type="checkbox" :value="item.value" :id="item.id" hidden v-model="curationInfo.emotion" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form_bottom">
                                <button
                                    style="text-align: center"
                                    v-if="
                                        curationInfo.target.length > 0 &&
                                            curationInfo.unit.length > 0 &&
                                            curationInfo.genre.length > 0 &&
                                            curationInfo.period.length > 0 &&
                                            curationInfo.color !== null &&
                                            curationInfo.material.length > 0 &&
                                            curationInfo.material.length < 4 &&
                                            (isApp || (!isApp && curationInfo.emotion.length > 2)) &&
                                            !btnDisalbed
                                    "
                                    @click="checkCondition()"
                                >
                                    다음
                                </button>
                                <!-- <button style="text-align:center;" v-else-if="!auth && curationInfo.target.length > 0 && curationInfo.unit.length > 0 && curationInfo.genre.length > 0 && curationInfo.period.length > 0"  @click="checkCondition()">다음</button> -->
                                <button v-else style="text-align: center; color: #5b5b5b; background: #4d1b24" disabled>다음</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import AlertModal from '@/components/modal/Space/AlertModal.vue';
import GenreSeasonAlertModal from '@/components/modal/Space/GenreSeasonAlertModal.vue';
import UpdateCurationDim from '@/components/other/UpdateCurationDim.vue';
import Loading from '@/components/modal/PlayerWS/common/Loading.vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import $ from 'jquery';
import { isEqual } from 'lodash';
import { updateCurationNotice } from '@/service/api/menuApi';
import {
  setMappingCurationAll,
  updateConfirmCuration,
  getMoodAndGenre,
  postRandomPreferMusic
} from '@/service/api/profileApi';
import { leaveChannel } from '@/service/api/webcastApi';
export default defineComponent({
  data () {
    return {
      isLoading: false,
      originalCurationInfo: {
        targetUnits: [],
        genre: [],
        period: [],
        emotion: []
      },
      spaceType: '',
      moreDetailYn: '',
      selectTab: 1,
      btnDisalbed: false,
      isApp: false,
      isAlertModal: false,
      alertText: '',
      isGenreSeasonAlertModal: false,
      notShowSeasonModalCarol: localStorage.getItem('notShowSeasonModalCarol'),
      disabledUp: false,
      disabledDown: false,
      selectInformation: true,
      spaceInfo: {},
      curationTemp: {
        mood: [],
        target: [],
        unit: [],
        domesticRate: '',
        genre: [],
        period: [],
        carMood: [],
        carPurpose: [],
        emotion: [],
        color: null,
        material: []
      },
      selectItem: {
        mood: [],
        target: [],
        unit: [],
        genre: [],
        period: [],
        carMood: [],
        carPurpose: [],
        emotion: []
      },
      curationInfo: {
        mood: null,
        target: [],
        unit: [],
        domesticRate: '',
        genre: [],
        period: [],
        carMood: null,
        carPurpose: [],
        emotion: [],
        color: null,
        material: []
      },
      allList: {
        mood: ['모던', '럭셔리', '빈티지', '아기자기', '트렌디'],
        target: ['유아/아동', '청소년', '청년', '중장년', '노년'],
        unit: ['혼자', '친구', '연인', '가족', '동료'],
        genre: [
          '발라드',
          '랩/힙합',
          '댄스',
          'R&B/Soul',
          '재즈',
          '일렉트로니카',
          '인디음악',
          '록/메탈',
          '포크/블루스/컨트리',
          '트로트',
          '클래식',
          '뉴에이지',
          '캐롤'
        ],
        period: ['~1980', '1990', '2000', '2010', '2020'],
        carPurpose: ['출퇴근용', '일상생활', '업무용', '드라이브용'],
        carMood: ['차분한 카페', '럭셔리한 호텔', '신나는 클럽', '활기찬 헬스장', '힐링 스파샵'],
        emotion: ['차분한', '편안한', '활기찬', '신나는', '슬픈', '쓸쓸한', '독특한', '파워풀한', '흥분되는']
      },
      auth: false
    };
  },
  mounted () {
    window.addEventListener('resize', this.resizeEvent);
  },
  components: {
    AlertModal,
    GenreSeasonAlertModal,
    'update-curation-dim': UpdateCurationDim,
    'channel-loading': Loading
  },
  methods: {
    setMoodAndGenre () {
      if (this.moreDetailYn !== 'Y') {
        getMoodAndGenre(this.curationInfo.color, this.curationInfo.material.sort((a, b) => a - b).join(',')).then(
          res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              const { mood, genre, emotion } = result;
              this.curationInfo.mood = parseInt(mood);
              this.curationInfo.genre = [];
              if (genre) {
                genre.forEach(item => {
                  const findItem = this.$store.getters['curation/getGenreValue'](item);
                  if (findItem) {
                    this.curationInfo.genre.push(parseInt(findItem.value));
                  }
                });
              }

              if (emotion) {
                this.curationInfo.emotion = [];
                emotion.forEach(item => {
                  const findItem = this.$store.getters['curation/getEmotionValue'](item);
                  if (findItem) {
                    this.curationInfo.emotion.push(parseInt(findItem.value));
                  }
                });
              }
            }
          }
        );
      }
    },
    clickColor (val) {
      this.curationInfo.color = val;
      this.setMoodAndGenre();
    },
    clickMaterial (val) {
      const findIdx = this.curationInfo.material.findIndex(value => value === val);

      if (findIdx > -1) {
        this.curationInfo.material.splice(findIdx, 1);
      } else {
        if (this.curationInfo.material.length < 3) {
          this.curationInfo.material.push(val);
        }
      }
      this.setMoodAndGenre();
    },
    async updateMoreDetail () {
      await axios
        .post('/api/profile/updateMoreDetail', {
          spaceId: localStorage.getItem('spaceId')
        })
        .then(res => {
          const { resultCd } = res.data;
          if (resultCd === '0000') {
            this.moreDetailYn = 'Y';
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    diffCuration () {
      this.curationInfo.period = this.curationInfo.period.map(item => parseInt(item)).sort((a, b) => a - b);
      this.curationInfo.genre = this.curationInfo.genre.map(item => parseInt(item)).sort((a, b) => a - b);
      this.curationInfo.emotion = this.curationInfo.emotion.map(item => parseInt(item)).sort((a, b) => a - b);
      const peroidEqual = isEqual(this.curationInfo.period, this.originalCurationInfo.period);
      const genreEqual = isEqual(this.curationInfo.genre, this.originalCurationInfo.genre);
      const emotionEqual = isEqual(this.curationInfo.emotion, this.originalCurationInfo.emotion);
      if (this.spaceInfo.spaceType === 'Store' || this.spaceInfo.spaceType === 'Building') {
        this.curationInfo.unit = this.curationInfo.unit.map(item => parseInt(item)).sort((a, b) => a - b);
        const targetUnitsEqual = isEqual(this.curationInfo.unit, this.originalCurationInfo.targetUnits);

        if (!targetUnitsEqual || !peroidEqual || !genreEqual || !emotionEqual) {
          return true;
        }
        return false;
      }
    },
    settingOriginalCuration () {
      if (this.spaceType === 'Store') {
        this.originalCurationInfo.targetUnits = this.curationInfo.unit.sort((a, b) => a - b);
      }
      this.originalCurationInfo.genre = this.curationInfo.genre.sort((a, b) => a - b);
      this.originalCurationInfo.period = this.curationInfo.period.sort((a, b) => a - b);
      this.originalCurationInfo.emotion = this.curationInfo.emotion.sort((a, b) => a - b);
    },
    onCloseDim () {
      this.moreDetailYn = 'Y';
      document.querySelector('#html').style.overflow = 'auto';
    },
    async getMySpaceInfo () {
      const spaceId = localStorage.getItem('spaceId');
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      return await axios
        .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
        .then(res => {
          return {
            success: true,
            data: res.data
          };
        })
        .catch(err => {
          return {
            success: false,
            data: err
          };
        });
    },
    checkApp () {
      // 앱, 설치형 플레이어 접근 체크 - 선호감정은 앱에서는 미노출 필요
      if (this.$store.state.installablePlayer) this.isApp = true;
    },
    setNotShowSeasonModalCarol () {
      // 캐롤 시즌 모달
      this.notShowSeasonModalCarol = 'Y';
      this.isGenreSeasonAlertModal = false;
    },
    alertModal (text) {
      this.isAlertModal = true;
      this.alertText = text;
    },
    checkCondition () {
      // 큐레이션 선택값 체크
      const periodNo = this.curationInfo.period.toString();
      if (this.curationInfo.domesticRate === '0' && this.curationInfo.genre.length === 1) {
        // 해외100% 장르 선택값이 1개인 경우
        if (this.curationInfo.genre[0] === 2 && periodNo === '1') {
          // 장르가 랩/힙합이고 시대가 1980s 인 경우
          this.alertModal(`해외 랩/힙합 노래만 선호하시는 경우,<br/> 1980's 이외의 시대도 선택해주세요.`);
        } else if (this.curationInfo.genre[0] === 6) {
          // 장르가 일렉트로니카인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? this.alertModal(`해외 일렉트로니카 노래만 선호하시는 경우,<br/> 1980s, 1990s 이외의 시대도 선택해주세요.`)
            : this.updateCuration();
        } else if (this.curationInfo.genre[0] === 12 && this.curationInfo.period.length < 3) {
          this.alertModal(`해외 뉴에이지 노래만 선호하시는 경우,<br/> 3개 이상의 시대를 선택해주세요.`);
        } else {
          this.updateCuration();
        }
      } else if (this.curationInfo.domesticRate === '0' && this.curationInfo.genre.length === 2) {
        // 해외100% 장르 선택값이 2개인 경우
        const check = this.curationInfo.genre.includes(2 || 6); // 장르 선택값이 랩/힙합, 일렉트로니카 인 경우 true
        check && periodNo === '1'
          ? this.alertModal(
              `해외 랩/힙합, 일렉트로니카 노래만 선호하시는 경우,<br /> 1980's 이외의 시대도 선택해주세요.`
          )
          : this.updateCuration(); // 시대가 1980s인 경우 안내창 오픈
      } else if (this.curationInfo.domesticRate === '100' && this.curationInfo.genre.length === 1) {
        // 국내100% 장르 선택값이 1개인 경우
        this.curationInfo.genre[0] === 4 && periodNo === '1'
          ? this.alertModal(`국내 R&B/Soul 노래만 선호하시는 경우,<br/> 1980's 이외의 시대도 선택해주세요.`)
          : this.updateCuration(); // 선택한 장르가 R&B/Soul이고 시대가 1980s인 경우 안내창 오픈
      } else if (this.curationInfo.domesticRate === '80' && this.curationInfo.genre.length === 1) {
        // 국내 80% 이고 선택장르가 1개인 경우
        if (this.curationInfo.genre[0] === 4 && periodNo === '1') {
          // 장르가 R&B/Soul이고 시대가 1980s 인 경우
          this.alertModal(`R&B/Soul 노래만 선호하시는 경우,<br/> 1980s 이외의 시대도 선택해주세요.`);
        } else if (this.curationInfo.genre[0] === 6) {
          // 장르가 일렉트로니카인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? this.alertModal(`일렉트로니카 노래만 선호하시는 경우,<br/> 1980s, 1990s 이외의 시대도 선택해주세요.`)
            : this.updateCuration();
        } else if (this.curationInfo.genre[0] === 5 && periodNo === '1') {
          // 장르가 재즈이고 시대가 1980s 인 경우
          this.alertModal(`재즈 노래만 선호하시는 경우,<br/> 1980s 이외의 시대도 선택해주세요.`);
        } else if (this.curationInfo.genre[0] === 2 && periodNo === '1') {
          // 장르가 랩/힙합이고 시대가 1980s 인 경우
          this.alertModal(`랩/힙합 노래만 선호하시는 경우,<br/> 1980's 이외의 시대도 선택해주세요.`);
        } else if (this.curationInfo.genre[0] === 12) {
          // 선택한 장르가 뉴에이지인 경우
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? this.alertModal(`뉴에이지 노래만 선호하시는 경우,<br/> 1980's, 1990's 이외의 시대도 선택해주세요.`)
            : this.updateCuration();
        } else {
          this.updateCuration();
        }
      } else if (this.curationInfo.domesticRate === '80' && this.curationInfo.genre.length === 2) {
        const checkGenre = this.curationInfo.genre.includes(4 || 12); // 장르 선택값이 R&B/Soul, 뉴에이지 인 경우 true
        checkGenre && periodNo === '1'
          ? this.alertModal(`R&B/Soul, 뉴에이지 노래만 선호하시는 경우,<br/> 1980s 이외의 시대도 선택해주세요.`)
          : this.updateCuration(); // 시대가 1980s인 경우 안내창 오픈
      } else if (this.curationInfo.domesticRate !== '0' && this.curationInfo.genre.length === 1) {
        // 해외100%가 아니고 선택한 장르가 1개인 경우
        if (this.curationInfo.genre[0] === 12) {
          // 선택한 장르가 뉴에이지인 경우
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? this.alertModal(`뉴에이지 노래만 선호하시는 경우,<br/> 1980's, 1990's 이외의 시대도 선택해주세요.`)
            : this.updateCuration();
        } else if (this.curationInfo.genre[0] === 2 && periodNo === '1') {
          // 선택한 장르가 랩/힙합이고 선택한 시대가 1980s인 경우
          this.alertModal(`랩/힙합 노래만 선호하시는 경우,<br/> 1980's 이외의 시대도 선택해주세요.`);
        } else if (this.curationInfo.genre[0] === 6) {
          // 장르가 일렉트로니카인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? this.alertModal(`일렉트로니카 노래만 선호하시는 경우,<br/> 1980s, 1990s 이외의 시대도 선택해주세요.`)
            : this.updateCuration();
        } else {
          this.updateCuration();
        }
      } else {
        this.updateCuration();
      }
    },
    countUp () {
      // 국내 비율 +
      this.disabledDown = false;
      if (parseInt(this.curationInfo.domesticRate) < 0) {
        this.curationInfo.domesticRate = '0';
      } else if (parseInt(this.curationInfo.domesticRate) >= 80) {
        this.curationInfo.domesticRate = '80';
        this.disabledUp = true;
      } else {
        this.disabledUp = false;
        const domesticRate = parseInt(this.curationInfo.domesticRate) + 10;
        this.curationInfo.domesticRate = domesticRate.toString();
      }
    },
    countDown () {
      // 국내 비율 -
      this.disabledUp = false;
      if (parseInt(this.curationInfo.domesticRate) <= 10) {
        // 국내 비율이 10%에서 0%로 바뀔때
        // const domesticRate = parseInt(this.curationInfo.domesticRate) - 10;
        // this.curationInfo.domesticRate = domesticRate.toString();
        this.curationInfo.domesticRate = '0';
        if (this.curationInfo.genre.includes(10)) {
          // 장르에 트로트가 포함된 경우 삭제
          this.curationInfo.genre = this.curationInfo.genre.filter(el => el !== 10);
        }
        this.disabledDown = true;
      } else if (parseInt(this.curationInfo.domesticRate) > 80) {
        this.curationInfo.domesticRate = '80';
      } else {
        this.disabledDown = false;
        const domesticRate = parseInt(this.curationInfo.domesticRate) - 10;
        this.curationInfo.domesticRate = domesticRate.toString();
      }
    },
    clickMood (e) {
      // 공간 분위기 클릭
      $(e.currentTarget.parentElement)
        .children('p')
        .removeClass('on');
      $(e.currentTarget).toggleClass('on');
      this.curationInfo.mood = e.currentTarget.querySelector('input').value;
    },
    clickTargetValue (val) {
      this.curationInfo.target = parseInt(val);
    },
    clickTarget (e) {
      // 주요 고객층 클릭
      $(e.currentTarget).toggleClass('on');
      const targetList = this.curationInfo.target;
      const target = parseInt(e.currentTarget.querySelector('input').value);

      const found = targetList.find(el => el === target);
      if (found != null) {
        // 선택한 값이 있을때
        const filtered = targetList.filter(el => el !== target);
        this.curationInfo.target = filtered;
      } else {
        // 선택한 값이 없을때
        this.curationInfo.target.push(target);
      }
    },
    clickUnit (e) {
      // 고객 단위 클릭
      $(e.currentTarget).toggleClass('on');
      const unitList = this.curationInfo.unit;
      const unit = parseInt(e.currentTarget.querySelector('input').value);

      const found = unitList.find(el => el === unit);
      if (found != null) {
        // 선택한 값이 있을때
        const filtered = unitList.filter(el => el !== unit);
        this.curationInfo.unit = filtered;
      } else {
        // 선택한 값이 없을때
        this.curationInfo.unit.push(unit);
      }
    },
    clickGenreVal (val) {
      const genreList = this.curationInfo.genre || [];
      if (this.curationInfo.domesticRate === '0' && parseInt(val) === 10) {
        this.alertModal(`해외곡만 선호하시는 경우, 트로트는 선택 불가합니다.`);
      } else {
        const findIndex = genreList.findIndex(item => parseInt(item) === parseInt(val));
        if (findIndex > -1) {
          //
          this.curationInfo.genre.splice(findIndex, 1);
        } else {
          //
          this.curationInfo.genre = [...this.curationInfo.genre, parseInt(val)];
        }
      }
    },
    clickGenre (e) {
      // 선호 장르 클릭
      const genreList = this.curationInfo.genre;
      const genre = parseInt(e.currentTarget.querySelector('input').value);
      if (this.curationInfo.domesticRate === '0' && genre === 10) {
        this.alertModal(`해외곡만 선호하시는 경우, 트로트는 선택 불가합니다.`);
      } else {
        const index = genreList.findIndex(el => el === 13);
        if (genre === 13) {
          if (index === -1) {
            this.curationInfo.genre = [genre];
            $(e.currentTarget)
              .parent()
              .children('p')
              .removeClass('on');
            if (this.notShowSeasonModalCarol !== 'Y') this.isGenreSeasonAlertModal = true;
          } else {
            this.curationInfo.genre.splice(index, 1);
          }
        } else {
          if (index !== -1) {
            this.curationInfo.genre.splice(index, 1);
            $('.xmas_tag')
              .parent()
              .removeClass('on');
          }
          const found = genreList.find(el => el === genre);
          if (found != null) {
            // 선택한 값이 있을때
            const filtered = genreList.filter(el => el !== genre);
            this.curationInfo.genre = filtered;
          } else {
            // 선택한 값이 없을때
            this.curationInfo.genre.push(genre);
          }
        }

        $(e.currentTarget).toggleClass('on');
      }
    },
    clickPeriod (e) {
      // 선호 시대 클릭
      if (this.curationInfo.genre[0] === 13) {
        this.alertModal(`캐롤 장르를 선택하실 경우,<br/>모든 시대의 캐롤을 재생해드립니다.`);
      } else {
        $(e.currentTarget).toggleClass('on');
        const periodList = this.curationInfo.period;
        const period = parseInt(e.currentTarget.querySelector('input').value);

        const found = periodList.find(el => el === period);
        if (found != null) {
          // 선택한 값이 있을때
          const filtered = periodList.filter(el => el !== period);
          this.curationInfo.period = filtered;
        } else {
          // 선택한 값이 없을때
          this.curationInfo.period.push(period);
        }
      }
    },

    clickEmotion (e) {
      // 선호 감정 클릭
      $(e.currentTarget).toggleClass('on');
      const emotionList = this.curationInfo.emotion;
      const emotion = parseInt(e.currentTarget.querySelector('input').value);

      const found = emotionList.find(el => el === emotion);
      if (found != null) {
        // 선택한 값이 있을때
        const filtered = emotionList.filter(el => el !== emotion);
        this.curationInfo.emotion = filtered;
      } else {
        // 선택한 값이 없을때
        this.curationInfo.emotion.push(emotion);
      }
    },

    selectMood () {
      const moodList = this.allList.mood;
      for (const i in moodList) {
        this.selectItem.mood[i] = this.curationTemp.mood.indexOf(moodList[i]) > -1;
      }
      const filter = moodList.indexOf(this.curationTemp.mood) + 1;
      if (filter > 0) {
        this.curationInfo.mood = filter;
      }
    },
    selectTarget () {
      const targetList = this.allList.target;
      for (const i in targetList) {
        this.selectItem.target[i] = this.curationTemp.target.indexOf(targetList[i]) > -1;
        const filter = targetList.indexOf(this.curationTemp.target[i]) + 1;
        if (filter > 0) {
          this.curationInfo.target.push(filter);
        }
      }
    },
    selectUnit () {
      const unitList = this.allList.unit;
      for (const i in unitList) {
        this.selectItem.unit[i] = this.curationTemp.unit.indexOf(unitList[i]) > -1;
        const filter = unitList.indexOf(this.curationTemp.unit[i]) + 1;
        if (filter > 0) {
          this.curationInfo.unit.push(filter);
        }
      }
    },
    selectGenre () {
      const genreList = this.allList.genre;
      for (const i in genreList) {
        this.selectItem.genre[i] = this.curationTemp.genre.indexOf(genreList[i]) > -1;
        const filter = genreList.indexOf(this.curationTemp.genre[i]) + 1;
        if (filter > 0) {
          this.curationInfo.genre.push(filter);
        }
      }
    },
    selectPeriod () {
      const periodList = this.allList.period;
      for (const i in periodList) {
        this.selectItem.period[i] = this.curationTemp.period.indexOf(periodList[i]) > -1;
        const filter = periodList.indexOf(this.curationTemp.period[i]) + 1;
        if (filter > 0) {
          this.curationInfo.period.push(filter);
        }
      }
    },
    selectEmotion () {
      const emotionList = this.allList.emotion;
      if (this.curationTemp.emotion) {
        for (const i in emotionList) {
          this.selectItem.emotion[i] = this.curationTemp.emotion.indexOf(emotionList[i]) > -1;
          const filter = emotionList.indexOf(this.curationTemp.emotion[i]) + 1;
          if (filter > 0) {
            this.curationInfo.emotion.push(filter);
          }
        }
      }
    },
    async getCurationInfo () {
      const spaceId = localStorage.getItem('spaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result.genre = result.genre.filter(genre => genre !== '인디음악'); // 인디음악을 선택한 경우 인디음악 제거

            setTimeout(() => {
              this.curationTemp.mood = result.mood;
              this.curationTemp.target = result.target;
              this.curationTemp.unit = result.targetUnit;
              this.curationTemp.domesticRate = result.domesticRate;
              this.curationInfo.domesticRate = result.domesticRate;
              this.curationTemp.genre = result.genre;
              this.curationTemp.period = result.period;
              this.curationTemp.carMood = result.carMood;
              this.curationTemp.carPurpose = result.carPurpose;
              this.curationTemp.emotion = result.emotion;

              this.selectMood();
              this.selectTarget();
              this.selectUnit();
              this.selectGenre();
              this.selectPeriod();
              this.selectEmotion();
              this.settingOriginalCuration();

              if (result.color) {
                // 색상
                const findColor = this.$store.getters['curation/getColorItem'](result.color);
                if (Object.keys(findColor).length > 0) {
                  this.curationInfo.color = findColor.no;
                }
              } else {
                this.curationInfo.color = null;
              }

              if (result.material) {
                this.curationInfo.material = result.material.map(
                  item => this.$store.getters['curation/getMaterialValue'](item).no
                );
              }
            }, 500);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    settingCurationValue () {
      let array = [];
      // 매장
      this.curationInfo.target.forEach(val => {
        const item = this.$store.getters['player/getTargetUnitFilter'](parseInt(val));
        array = [...array, ...item];
      });
      const set = new Set(array);
      this.curationInfo.unit = Array.from(set);
    },
    async updateCuration () {
      this.isLoading = true;
      const resultDiff = this.diffCuration();

      if (resultDiff && this.spaceInfo.moreDetailYn !== 'Y') await this.updateMoreDetail();
      if (!resultDiff && this.moreDetailYn !== 'Y' && this.spaceInfo.moreDetailYn !== 'Y') this.settingCurationValue();
      this.btnDisalbed = true;
      const mood = this.curationInfo.mood;
      const target = this.curationInfo.target
        .sort((a, b) => {
          return a - b;
        })
        .toString();
      const targetUnit = this.curationInfo.unit
        .sort((a, b) => {
          return a - b;
        })
        .toString();
      let domesticRate = this.curationInfo.domesticRate;
      if (parseInt(domesticRate) < 0) domesticRate = '0';
      const genre = this.curationInfo.genre
        .sort((a, b) => {
          return a - b;
        })
        .toString();
      if (genre === '10') domesticRate = '80';
      let period = this.curationInfo.period
        .sort((a, b) => {
          return a - b;
        })
        .toString();
      if (genre === '13') period = [1, 2, 3, 4, 5].toString();
      const spaceId = localStorage.getItem('spaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const foreignRate = String(100 - parseInt(domesticRate));
      const emotion =
        this.curationInfo.emotion.length > 0
          ? this.curationInfo.emotion
            .sort((a, b) => {
              return a - b;
            })
            .toString()
          : null;
      const curationInfo = {
        spaceId,
        mood,
        target,
        targetUnit,
        domesticRate,
        foreignRate,
        genre,
        period,
        emotion
      };

      if (this.spaceInfo.spaceType === 'Store' || this.spaceInfo.spaceType === 'Building') {
        curationInfo.color = this.curationInfo.color;
        curationInfo.material = this.curationInfo.material.sort((a, b) => a - b).join(',');
        this.updateStoreCuration(curationInfo);
      } else {
        try {
          const { data: curationTempResult } = await this.updateTempCuration(curationInfo, headers);
          const { resultCd: curationTempResultCd } = curationTempResult;
          if (curationTempResultCd === '0000') {
            localStorage.setItem('spaceId', spaceId);
            const { data: updateCurationData } = await this.updateConfirmCuration(spaceId, headers);
            const { resultCd: updateCd } = updateCurationData;
            if (updateCd === '0000') {
              // Blur Loading
              const confirmResult = await this.setConfirmMusic();
              if (confirmResult) {
                //
                this.goPlayer();
              }
              this.isLoading = false;
            } else {
              this.failUpdateCuration();
            }
          }
        } catch (error) {
          console.error('updateCuration error : ', error);
          this.failUpdateCuration();
        } finally {
          this.btnDisalbed = false;
        }
      }
    },
    /**
     * @description 플레이어이동
     */
    async goPlayer () {
      const userId = this.$cookies.get('userId');
      const spaceId = this.$cookies.get('spaceId');
      const channelId = this.$store.state.channelId;
      const path = localStorage.getItem('seongdongpath');

      // 채널정보 클리어
      if (channelId !== '') {
        await leaveChannel(userId, spaceId, channelId);
        this.$store.commit('setChannelId', '');
        this.$store.commit('setChannelUrl', null);
      }

      if (this.$store.state.isBrandMusic) {
        this.$store.commit('setIsBrandMusicPlay', false); // 브랜드 음원의 재생 값 false
        this.$store.commit('setIsBrandMusic', false); // 브랜드 음원 값 false
      }
      this.$cookies.set('spaceId', localStorage.getItem('spaceId'));
      this.$store.commit('setSpaceNm', this.spaceNm);
      this.$store.commit('setSpaceId', '');
      this.$store.commit('setMute', false);
      if (this.spaceInfo.spaceType === 'Building') {
        const updateCurationForBuilding = { spaceId: this.spaceInfo.spaceId, isChange: true };
        this.$store.commit('setUpdateCurationForBuilding', updateCurationForBuilding);
      }
      if (this.spaceInfo.spaceType === 'Store') {
        this.$VideoPlayer.onPause();
      }

      if (path) {
        this.$router.push({ name: path, params: { spaceState: 'changeSpace' } }).catch(() => {});
        localStorage.removeItem('seongdongpath');
      } else {
        this.$router.push({ name: 'PlayerView', params: { curUpdate: 'updated', setIsLoading: true } }).catch(() => {});
      }
    },
    /**
     * @description 음원 랜덤 확정, 큐레이션 확정
     */
    async setConfirmMusic () {
      try {
        const spaceId = localStorage.getItem('spaceId');
        const noticeTypeDetail = this.$cookies.get('userId');
        const aToken = this.$cookies.get('aToken');
        const headers = { 'X-AUTH-TOKEN': aToken };

        const { data: preferMusicData } = await postRandomPreferMusic(spaceId);
        const { resultCd: preferMusicResult } = preferMusicData;
        if (preferMusicResult === '0000') {
          const { data: noticeTypeData } = await updateCurationNotice(spaceId, noticeTypeDetail, headers);
          const { resultCd: noticeTypeResultCd } = noticeTypeData;
          if (noticeTypeResultCd === '0000') {
            //
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    /**
     *
     * @param {*} curationInfo
     */
    async updateStoreCuration (curationInfo) {
      const spaceId = localStorage.getItem('spaceId');
      try {
        localStorage.setItem('spaceId', spaceId);
        if (this.moreDetailYn === 'Y') {
          const moodResult = await getMoodAndGenre(curationInfo.color, curationInfo.material);
          if (moodResult.data.resultCd === '0000') {
            const { result: moodRes } = moodResult.data;
            const { mood } = moodRes;
            curationInfo.mood = parseInt(mood);
          }
        }
        const result = await setMappingCurationAll(curationInfo);
        const { resultCd: mappingCurationResultCd } = result.data;
        if (mappingCurationResultCd === '0000') {
          const { data } = await updateConfirmCuration(spaceId);
          const { resultCd: confirmResultCd } = data;
          if (confirmResultCd === '0000') {
            // Blur
            const confirmResult = await this.setConfirmMusic();
            if (confirmResult) {
              if (this.$store.state.installablePlayer) {
                this.$router.push({ name: 'InstallablePlayerUpdateCuration' });
              } else {
                this.goPlayer();
              }
            }
            this.isLoading = false;
          } else {
            this.failUpdateCuration();
          }
        } else {
          this.failUpdateCuration();
        }
      } catch (error) {
        console.error('updateStoreCuration error : ', error);
      }
    },
    /**
     * @description 실패 alert
     * @author CHOI DAE GEON
     */
    failUpdateCuration () {
      alert('선호음악 선정에 실패하였습니다.');
    },
    /**
     * @description 큐레이션 정보 임시저장
     * @param {*} curationInfo 큐레이션정보
     * @param {*} headers http 헤더
     * @author CHOI DAE GEON
     */
    async updateTempCuration (curationInfo, headers) {
      return await axios.post('/api/profile/updateCurationTemp', curationInfo, { headers });
    },
    /**
     * @description 큐레이션 정보확정
     * @param {*} spaceId 스페이스ID
     * @param {*} headers http 헤더
     * @author CHOI DAE GEON
     */
    async updateConfirmCuration (spaceId, headers) {
      return await axios.post('/api/profile/updateCuration', { spaceId }, { headers });
    },
    clickPre () {
      history.back();
    },

    async resizeEvent () {
      const width = document.querySelector('#html').clientWidth;
      if (width < 500) {
        await this.$nextTick();
        if (this.selectTab === 2 && this.moreDetailYn !== 'Y') {
          document.querySelector('#html').style.overflow = 'hidden';
          if (document.querySelector('#mobiledim')) {
            document.querySelector('#mobiledim').style.height = `${document.querySelector('#html').clientHeight -
              86 -
              155}px`;
          }
        } else {
          document.querySelector('#html').style.overflow = 'auto';
          if (document.querySelector('#mobiledim')) {
            document.querySelector('#mobiledim').style.height = `100%`;
          }
        }
      }
    }
  },

  async created () {
    this.getCurationInfo();
    this.checkApp();
    const returnValue = await this.getMySpaceInfo();
    const { data } = returnValue;
    const { result } = data;
    this.spaceInfo = result;
    this.moreDetailYn = result.moreDetailYn;
    this.spaceType = result.spaceType;
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeEvent);
  },
  watch: {
    async selectTab (val) {
      await this.$nextTick();
      if (val === 2 && this.moreDetailYn !== 'Y') {
        document.querySelector('#html').style.overflow = 'hidden';
        if (document.querySelector('#mobiledim')) {
          document.querySelector('#mobiledim').style.height = `${document.querySelector('#html').clientHeight -
            86 -
            155}px`;
        }
      } else {
        document.querySelector('#html').style.overflow = 'auto';
        if (document.querySelector('#mobiledim')) {
          document.querySelector('#mobiledim').style.height = `100%`;
        }
      }
    },

    'curationInfo.target' (val) {
      if (this.moreDetailYn !== 'Y') {
        let array = [];
        val.forEach(targetval => {
          const item = this.$store.getters['player/getTargetUnitFilter'](parseInt(targetval));
          array = [...array, ...item];
        });
        const set = new Set(array);
        this.curationInfo.unit = Array.from(set);
      }
    }
  },
  computed: {
    pcGetTarget () {
      return this.$store.getters['curation/getTarget'];
    },
    pcGetTargetunit () {
      return this.$store.getters['curation/getTargetUnit'];
    },
    pcGenre () {
      return this.$store.getters['curation/getGenreAll'];
    },
    pcPeroid () {
      return this.$store.getters['curation/getPeroid'];
    },
    pcEmotion () {
      return this.$store.getters['curation/getEmotion'];
    },
    pcGetColors1 () {
      return this.$store.getters['curation/getCurationColors'].slice(0, 8);
    },
    pcGetColors2 () {
      return this.$store.getters['curation/getCurationColors'].slice(8, 16);
    },
    pcGetMaterial1 () {
      return this.$store.getters['curation/getCurationMaterial'].slice(0, 8);
    },
    pcGetMaterial2 () {
      return this.$store.getters['curation/getCurationMaterial'].slice(8, 10);
    },
    moGetColors1 () {
      return this.$store.getters['curation/getCurationColors'];
    },
    moGetColors2 () {
      return this.$store.getters['curation/getCurationColors'].slice(5, 10);
    },
    moGetColors3 () {
      return this.$store.getters['curation/getCurationColors'].slice(10, 15);
    },
    moGetColors4 () {
      return this.$store.getters['curation/getCurationColors'].slice(15);
    },
    moGetMaterial () {
      return this.$store.getters['curation/getCurationMaterial'];
    }
  }
});
</script>

<style scoped src="@/assets/css/updatecuration.css"></style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
